@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'ABCFavorit';
  src: local('ABCFavorit'), local('ABCFavorit'),
    url('./fonts/ABCFavoritVariable.woff2') format('woff2'),
    url('./fonts/ABCFavoritVariable.woff') format('woff');
}

@font-face {
  font-family: 'ABCFavorit Book';
  src: local('ABCFavorit Book'), local('ABCFavorit-Book'),
    url('./fonts/ABCFavoritVariable.woff2') format('woff2'),
    url('./fonts/ABCFavoritVariable.woff') format('woff');
}
