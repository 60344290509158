@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.vimeo-container {
  position: relative;
  background-color: black;
}

.right-float {
  margin-left: auto;
  margin-right: 0;
}

iframe {
  position: absolute;
  left: 50%;
  top: 50%;
  min-width: 100%;
  height: 100%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 0;
}

.video-overlay {
  position: relative;
  z-index: 3;
}

.heightFull {
  height: 100%;
}

@layer components {
  h1 {
    @apply font-favorit text-5xl font-medium leading-6;
  }

  h2 {
    @apply font-favorit text-4xl font-medium leading-6;
  }

  h3 {
    @apply font-favorit text-3xl font-medium leading-5;
  }

  .book-info-sm {
    @apply font-favoritBook text-sm font-book leading-4;
  }

  .book-info-md {
    @apply font-favoritBook text-base font-book leading-4;
  }

  .book-intro-sm {
    @apply font-favoritBook font-book text-md leading-4;
  }

  .book-intro-md {
    @apply font-favoritBook font-book text-xl leading-4;
  }

  .book-intro-lg {
    @apply font-favoritBook font-book text-2xl leading-5;
  }

  .medium-intro-sm {
    @apply font-favorit text-md font-medium leading-4;
  }

  .medium-intro-md {
    @apply font-favorit text-xl font-medium leading-4;
  }

  .medium-intro-lg {
    @apply font-favorit text-2xl font-medium leading-5;
  }

  .bold-intro-sm {
    @apply font-favorit text-md font-bold leading-4;
  }

  .bold-intro-md {
    @apply font-favorit text-xl font-bold leading-4;
  }

  .bold-intro-lg {
    @apply font-favorit text-2xl font-bold leading-5;
  }

  .global-margin {
    @apply max-w-screen-2xl;
    @apply mx-7;
    @apply md:mx-10;
    @apply xl:mx-20;
    @apply 2xl:mx-auto;
  }
}

.bg-pattern {
  background-image: url('./images/patter_treesAI_01.svg');
  background-repeat: no-repeat;
  background-size: 90%;
}

.bg-header {
  background-image: url('./images/header.svg');
  background-size: 100%;
  background-repeat: no-repeat;
}

.bg-problemSolution {
  background-image: url('./images/bg-statement.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-pd {
  background-image: url('./images/pd-shape.svg');
  background-repeat: no-repeat;
}

.bg-investor {
  background-image: url('./images/invest-shape.svg');
  background-repeat: no-repeat;
}

.bg-registry {
  background-image: url('./images/registry.svg');
  background-repeat: no-repeat;
}

.bg-invest-contact {
  background-image: url('./images/invest-with-us.svg');
  background-repeat: no-repeat;
}

.title-box-info {
  border-top-left-radius: 10rem;
  border-top-right-radius: 10rem;
}

.title-text-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.measure-background-shape {
  background: url('./images/upload.svg') top no-repeat;
}

.list-background-shape {
  background: url('./images/explore.svg') top no-repeat;
}

.plan-background-shape {
  background: url('./images/demo.svg') top no-repeat;
}
